import axios from '../axios/instanceAxios'
import axios2 from '../axios/axiosInstWOHead'
import { encryption } from '../utils/encryption'

export const GetMemberDetails = async (UserType) => {
  const MemberToken = localStorage.getItem('MemberToken')
  return await axios.get(`/member/member-details/v1`, 
  { headers: { UserType: UserType || 'M', Authorization: MemberToken } })
}

export const UpdateMemberProfile = async ({ sProfilePic, ...rest}) => {
  if (typeof sProfilePic === 'object') {
    const response = await axios.post('/member/pre-signed-url/v1', { sFileName: sProfilePic?.name, sContentType: sProfilePic?.type })
    const url = response?.data?.data?.sUrl
    const sImage = response?.data?.data?.sPath
    await axios2.put(url, sProfilePic, { headers: { 'Content-Type': 'multipart/form-data' } })
    return await axios.put(`/member/update-member-profile/v1`, { sProfilePic: sImage }, { headers: { UserType: 'M' } })
  } else {
    return await axios.put(`/member/update-member-profile/v1`, rest, { headers: { UserType: 'M' } })
  }
}

export const ChangeMemberPassword = async (changePasswordData) => {
  const { sEmail, nOtp, sOldPassword, sNewPassword, sConfirmPassword, sUserType } = changePasswordData
  const encryptedOldPass = encryption(sOldPassword);
  const encryptedPass = encryption(sNewPassword);
  const encryptedConfirmPass = encryption(sConfirmPassword);
  return await axios.post(`/member/change-password/v1`, {
    sEmail, nOtp, sOldPassword: encryptedOldPass, sNewPassword: encryptedPass, sConfirmPassword: encryptedConfirmPass
  }, { headers: { UserType: sUserType }})
}
