import './App.css';
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes/index'
import { useMyContext } from './context/context';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { GetUrl } from './api/url'

function App() {
  const { dispatch } = useMyContext()
  // const [isLaunchBtnVisible, setIsLaunchBtnVisible] = useState(true)
  // const [isLaunchVisible, setIsLaunchVisible] = useState(true)

  const { data: sMediaUrl } = useQuery({
    queryKey: ['GetUrl'],
    queryFn: GetUrl,
    select: (response) => response?.data?.data
  })

  useEffect(() => {
    if (sMediaUrl) dispatch({ type: 'MEDIA_URL', payload: sMediaUrl })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sMediaUrl])

  // useEffect(() => {
  //   if (isLaunchVisible && (!isLaunchBtnVisible)) {
  //     setTimeout(() => {
  //       setIsLaunchVisible(false)
  //     }, 5000)
  //   }
  // }, [isLaunchVisible, isLaunchBtnVisible])

  // const handleLaunch = () => {
  //   if (isLaunchBtnVisible) setIsLaunchBtnVisible(false)
  //   else setIsLaunchVisible(false)   
  // };

  return (
    <BrowserRouter>
      {/* {isLaunchVisible &&
      <LaunchPopup
        handleLaunch={handleLaunch}
        isLaunchBtnVisible={isLaunchBtnVisible}
      />} */}
      <Routes />
    </BrowserRouter>
  );
}

export default App;
