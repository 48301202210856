import axios from '../axios/instanceAxios'
import { encryption } from '../utils/encryption'

export const GetStudentDetails = async (UserType) => {
  const StudentToken = localStorage.getItem('StudentToken')
  return await axios.get(`/student/student-details/v1`, 
  { headers: { UserType: UserType || 'S', Authorization: StudentToken } })
}

export const UpdateStudentProfile = async (updateProfileData) => {
  const { sUserType } = updateProfileData
  return await axios.put('/student/update-student-profile/v1', updateProfileData, { headers: { UserType: sUserType } })
}

export const ChangeStudentPassword = async (changePasswordData) => {
  const { sEmail, nOtp, sOldPassword, sNewPassword, sConfirmPassword, sUserType } = changePasswordData
  const encryptedOldPass = encryption(sOldPassword);
  const encryptedPass = encryption(sNewPassword);
  const encryptedConfirmPass = encryption(sConfirmPassword);
  return await axios.post(`/student/change-password/v1`, {
    sEmail, nOtp, sOldPassword: encryptedOldPass, sNewPassword: encryptedPass, sConfirmPassword: encryptedConfirmPass
  }, { headers: { UserType: sUserType }})
}