import axios from '../axios/instanceAxios'
import axios2 from '../axios/axiosInstWOHead'
import { encryption } from '../utils/encryption'

export const SendOtp = async (sendOtpData) => {
  const { sUserType } = sendOtpData
  return await axios.post(`/auth/${sUserType === 'M' ? 'member' : sUserType === 'S' ? 'student' : 'member'}/send-otp/v1`, sendOtpData, { headers: { UserType: sUserType }})
}

export const Register = async (registerData) => {
  const { sUserType, sFirmCompanyName, bIsGSTNumberAvailable, sGSTNumber, sConfirmGSTNumber, bIsGetUpdateOnEmail, bIsGetUpdateOnWhatsapp, sProfilePic, sCourse, sFullName, sEmail, sMobileNumber, sMembershipNumber, sRegistrationNumber, sDob, oState, oCity, sPassword, sConfirmPassword, nOtp } = registerData
  if (sUserType === 'M') {
    const response = await axios.post(`/member/pre-signed-url/v1`, { sFileName: sProfilePic?.name, sContentType: sProfilePic?.type })
    const url = response?.data?.data?.sUrl
    const sImage = response?.data?.data?.sPath
    const payload = sUserType === 'M'
      ? { sFirmCompanyName, bIsGSTNumberAvailable, sGSTNumber, sConfirmGSTNumber, bIsGetUpdateOnEmail, bIsGetUpdateOnWhatsapp, sProfilePic: sImage, sFullName, sEmail, sMobileNumber, sMembershipNumber, sDob, oState, oCity, sPassword, sConfirmPassword, nOtp }
      : ''
    await axios2.put(url, sProfilePic, { headers: { 'Content-Type': 'multipart/form-data' } })
    return await axios.post(`/auth/member/register/v1`, payload, { headers: { UserType: sUserType }})
  } else {
    const payload = sUserType === 'S' ? { sCourse, sFullName, sEmail, sMobileNumber, sRegistrationNumber, sDob, oState, oCity, sPassword, sConfirmPassword, nOtp } : ''
    return await axios.post(`/auth/student/register/v1`, payload, { headers: { UserType: sUserType }})
  }
}

export const Login = async (loginData) => {
  const { sMembershipNumber, sRegistrationNumber, sDob, sPassword, sUserType } = loginData;
  const encryptedPass = encryption(sPassword);
  const url = `/auth/${sUserType === 'M' ? 'member' : sUserType === 'S' ? 'student' : 'member'}/login/v1`;
  const data = sUserType === 'M' 
    ? { sMembershipNumber, sDob, sPassword: encryptedPass } 
    : { sRegistrationNumber, sDob, sPassword: encryptedPass };
  const config = { headers: { UserType: sUserType } };
  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const ResetPassword = async (resetPasswordData) => {
  const { sEmail, nOtp, sNewPassword, sConfirmPassword, sUserType } = resetPasswordData
  return await axios.post(`/auth/${sUserType === 'M' ? 'member' : sUserType === 'S' ? 'student' : 'member'}/reset-password/v1`, {
    sEmail, nOtp, sNewPassword, sConfirmPassword
  }, { headers: { UserType: sUserType }})
}

export const Logout = async (logoutData) => {
  const { sEmail, sUserType } = logoutData
  const MemberToken = localStorage.getItem('MemberToken')
  const StudentToken = localStorage.getItem('StudentToken')
  return await axios.post(`/auth/${sUserType === 'M' ? 'member' : sUserType === 'S' ? 'student' : 'member'}/logout/v1`, { sEmail },
  { headers: { UserType: sUserType, Authorization: sUserType === 'M' ? MemberToken : sUserType === 'S' ? StudentToken : MemberToken } })
}
