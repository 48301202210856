import axios from '../axios/instanceAxios'
import { stringify } from '../utils/Stringify'

export const GetNewsletters = async (newsletterQuery) => {
  const queryParams = stringify(newsletterQuery)
  return await axios.get(`/newsletters/list/v1?${queryParams}`)
}

export const SubscribeNewsletter = async (payload) => {
  return await axios.post('/newsletter/subscribe/v1', payload)
}