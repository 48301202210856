export function allValuesExist (obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      if (value === null || value === undefined || value === '') {
        return false
      }
      if (Array.isArray(value) && value.length === 0) {
        return false
      }
      if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
        return false
      }
    }
  }
  return true
}
