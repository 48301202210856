import React, { Suspense, useEffect } from 'react'
// import PropTypes from 'prop-types'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import Loader from '../components/Loader/Loader'
// import Sidebar from './Sidebar'
import { useQuery } from '@tanstack/react-query'
import { GetMemberDetails } from '../api/member'
import { GetStudentDetails } from '../api/student'

function HeaderFooterLayout(props) {
  const { pathname } = useLocation();
  const MemberToken = localStorage.getItem('MemberToken')
  const StudentToken = localStorage.getItem('StudentToken')

  const { data: MemberDetails, refetch: refetchMemberProfile } = useQuery({
    queryKey: ['GetMemberDetails'],
    queryFn: () => GetMemberDetails('M'),
    select: (response) => response?.data?.data,
    enabled: !!MemberToken
  });

  const { data: StudentDetails, refetch: refetchStudentProfile } = useQuery({
    queryKey: ['GetStudentDetails'],
    queryFn: () => GetStudentDetails('S'),
    select: (response) => response?.data?.data,
    enabled: !!StudentToken
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <Header
        {...props}
        MemberDetails={MemberDetails}
        StudentDetails={StudentDetails}
        refetchMemberProfile={refetchMemberProfile}
        refetchStudentProfile={refetchStudentProfile}
      />
      {/* <div className='d-flex'> */}
        {/* {((MemberToken && pathname.includes('/member')) || (StudentToken && pathname.includes('/student'))) 
        && <Sidebar
            MemberDetails={MemberDetails} 
            StudentDetails={StudentDetails}
            refetchMemberProfile={refetchMemberProfile}
            refetchStudentProfile={refetchStudentProfile}
          />} */}
        {/* <div className='sidebar-content'> */}
      <Outlet context={{ ...props, MemberDetails, StudentDetails, refetchMemberProfile, refetchStudentProfile }} />
        {/* </div> */}
      {/* </div> */}
      <Footer />
    </Suspense>
  )
}

HeaderFooterLayout.propTypes = {}

export default HeaderFooterLayout
