import { Crypt } from 'hybrid-crypto-js'

const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA8WMqYgY92O+DgGbw1PQs
K0DxQQHH3lzE/II61Ri6Wt3KWk11PFK87IlqWb8n3O/NkKe6u1fdUa+MRxnaEMXG
ZJfQBkeyCcUOx9wy2fK2pDWe9g1FP09kMh+WIiVJOABgvRdrFT4/tRGGLszPRDFr
rvdNFdeYiMUU6JLuAr43LHnPghIPjPDS7mQ22VEzIObhoipcvAqV2aix1EPC+Jv8
fqSj6EzAK98mxFLm13PgSY15wlAYvS3FSaHx0lPvGi58bCiFgu2MfheyyHaGRPfx
NBuGVfsayEI/c0wO1tLVQkY3kf+EC83tC51UerQw0P1zrZApGNaW/dUECgMBoi4k
lmh/4QfMYpkyBoBGrEgas6yv2n6zTAd1gF61qxbdnuSz6pBOEeqU9M55YsdoKlNV
rApoMakFN1XIK3IunfuAEUZspBeWB8bKgTskouj5oGjnm4mfMaaCEvXZ2eg5BAND
UE7tciM9wY0vDsyq0HmaKUKsdYK1aiOjGR0x3Haz9nCyFAQlAa26pCOlHDADWWUj
E+Mi6UWS2MwGStqpkGTSnOISk9R+odd9Cf1CAyTKd0poSbxwsMbmiti8mjxQE4Np
AfK7MQKnWBmvZicQppTOIOyuHpV2tFfPLKXRw0shDWBW2xoGqj0Z+hLyCcFGkGG5
P9Fx56q+4rUnqp9fEI+B9osCAwEAAQ==
-----END PUBLIC KEY-----`

export const encryption = function (sPassword) {
  const crypt = new Crypt()
  const encryptedPassword = crypt.encrypt(publicKey, sPassword)
  return JSON.stringify(encryptedPassword);
}