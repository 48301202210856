export const stringify = (obj, prefix) => {
  const queryString = Object.keys(obj).map(key => {
    const value = obj[key]
    if (value === undefined || value === null) {
      return null
    }
    const encodedKey = encodeURIComponent(prefix ? `${prefix}[${key}]` : key)
    if (Array.isArray(value)) {
      return value.map((item, index) => {
        if (item === undefined || item === null) {
          return null
        }
        return `${encodedKey}[${index}]=${encodeURIComponent(item)}`
      }).filter(Boolean).join('&')
    } else if (typeof value === 'object') {
      return stringify(value, encodedKey)
    }
    return `${encodedKey}=${encodeURIComponent(value)}`
  }).filter(Boolean).join('&')
  return queryString
}
