import React from 'react'
import PropTypes from 'prop-types'
import { Toast, ToastContainer } from 'react-bootstrap'

function AlertComponent (props) {
  const { success, message, alert, setAlert } = props

  return (
  <ToastContainer>
    <Toast 
      animation={true}
      bg={success ? 'success' : 'danger'} 
      show={alert}
      onClose={() => setAlert(false)}
    >
       <Toast.Header className={`bg-${success ? 'success' : 'danger'}`}>
        <strong className="mr-auto">{success ? 'Success' : 'Error'}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  </ToastContainer>)
}

AlertComponent.propTypes = {
  success: PropTypes.bool,
  message: PropTypes.string,
  alert: PropTypes.bool,
  setAlert: PropTypes.func
}

export default AlertComponent
