import axios from 'axios'
import config from '../config/config'
import { unAuthorized } from '../utils/unAuthorized'

const instanceAxios = axios.create({
  baseURL: config.BASE_URL
})

instanceAxios.interceptors.request.use((req) => {
  const MemberToken = localStorage.getItem('MemberToken')
  const StudentToken = localStorage.getItem('StudentToken')
  const token = req.headers.UserType === 'M' ? MemberToken : req.headers.UserType === 'S' ? StudentToken : ''
  if (!req.headers.Authorization && token && !req.headers.noAuth) {
    req.headers.Authorization = token
    return req
  }
  return req
}, (err) => Promise.reject(err))

instanceAxios.interceptors.response.use(response => response, async (error) => {
  if ((error.response && error.response.status === 401)) {
    unAuthorized()
  }
  return Promise.reject(error)
})

export default instanceAxios
