const reducer = (state = { }, action) => {
  switch (action.type) {
    case 'MEMBER_TOKEN':
      return {
        ...state,
        memberToken: action.payload
      }
    case 'STUDENT_TOKEN':
      return {
        ...state,
        studentToken: action.payload
      }
    case 'MEDIA_URL':
      return {
        ...state,
        sMediaUrl: action.payload
      }
    default:
      return state
  }
}

export default reducer;