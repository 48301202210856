import React, { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import HeaderFooterLayout from '../layouts/HeaderFooter'
const PublicRoute = lazy(async () => import('./PublicRoute'))
const PrivateRoute = lazy(() => import('./PrivateRoute'))
// const NotFound = lazy(() => import('../components/NotFound'))
// const Loader = lazy(() => import('../components/Loader/Loader'))
const Home = lazy(() => import('../pages/Home/index'))
const Overview = lazy(() => import('../pages/Overview/Overview'))
const ManagingCommittee = lazy(() => import('../pages/ManagingCommittee'))
const Login = lazy(() => import('../pages/Users/Login'))
const Registration = lazy(() => import('../pages/Users/Registration'))
const ForgotPassword = lazy(() => import('../pages/Users/ForgotPassword'))
const MemberIndex = lazy(() => import('../pages/Users/Members/index'))
const StudentProfile = lazy(() => import('../pages/Users/Students/StudentProfile'))
const Events = lazy(() => import('../pages/Events/Events'))
const UpcomingEvents = lazy(() => import('../pages/Events/UpcomingEvents'))
const Gallery = lazy(() => import('../pages/Gallery/index'))
const ViewGallery = lazy(() => import('../pages/Gallery/ViewGallery/index'))
const Newsletters = lazy(() => import('../pages/Newsletter'))
const MessageContent = lazy(() => import('../pages/MessageContent'))
const Services = lazy(() => import('../pages/Services'))
const Resources = lazy(() => import('../pages/Resources'))
const Policies = lazy(() => import('../pages/Policies'))
const EventDetails = lazy(() => import('../pages/Events/EventDetails'))
const DownloadReceipt = lazy(() => import('../components/DownloadReceipt'))

export default function Router () {
  return useRoutes([
    {
      path: '/overview',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Overview} /> }
      ]
    },
    {
      path: '/managing-committee',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={ManagingCommittee} /> }
      ]
    },
    {
      path: '/message-content',
      element: <HeaderFooterLayout />,
      children: [
        { path: ':personId', element: <PublicRoute element={MessageContent} /> }
      ]
    },
    {
      path: '/register',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Registration} /> }
      ]
    },
    {
      path: '/member',
      element: <HeaderFooterLayout />,
      children: [
        { path: 'login', element: <PublicRoute element={Login} /> },
        { path: 'forgot-password', element: <PublicRoute element={ForgotPassword} /> },
        { path: 'cpe', element: <PrivateRoute element={MemberIndex} /> },
        { path: 'profile', element: <PrivateRoute element={MemberIndex} /> },
        { path: 'change-password', element: <PrivateRoute element={MemberIndex} /> },
        { path: 'payments', element: <PrivateRoute element={MemberIndex} /> },
        { path: 'announcements', element: <PrivateRoute element={MemberIndex} /> },
        { path: 'download/receipt', element: <PrivateRoute element={DownloadReceipt} /> },
        { path: '', element: <Navigate to="/member/cpe" /> }
      ]
    },
    {
      path: '/student',
      element: <HeaderFooterLayout />,
      children: [
        { path: 'login', element: <PublicRoute element={Login} /> },
        { path: 'forgot-password', element: <PublicRoute element={ForgotPassword} /> },
        { path: 'update-profile', element: <PrivateRoute element={StudentProfile} /> },
        { path: 'change-password', element: <PrivateRoute element={MemberIndex} /> },
        { path: 'download/receipt', element: <PrivateRoute element={DownloadReceipt} /> },
        { path: '', element: <Navigate to="/student/update-profile" /> }
      ]
    },
    {
      path: '/message-content',
      element: <HeaderFooterLayout />,
      children: [
        { path: ':personId', element: <PublicRoute element={MessageContent} /> }
      ]
    },
    {
      path: '/resources',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Resources} /> }
      ]
    },
    {
      path: '/services',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Services} /> }
      ]
    },
    {
      path: '/policies',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Policies} /> },
        // { path: ':policyId', element: <PublicRoute element={Policies} /> },
      ]
    },
    {
      path: '/events',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Events} /> },
        { path: 'upcoming-events', element: <PublicRoute element={UpcomingEvents} /> },
        { path: 'event-details/:iEventId', element: <PublicRoute element={EventDetails} /> },
      ]
    },
    {
      path: '/gallery',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Gallery} /> },
        { path: 'view-gallery/:iRecordId', element: <PublicRoute element={ViewGallery} /> }
      ]
    },
    {
      path: '/newsletters',
      element: <HeaderFooterLayout />,
      children: [
        { path: '', element: <PublicRoute element={Newsletters} /> },
      ]
    },
    {
      path: '/',
      element: <HeaderFooterLayout />,
      children: [
        { path: '/', element: <PublicRoute element={Home} /> },
        // { path: '/404', element: <Suspense fallback={<Loader />}><NotFound /></Suspense> },
        { path: '*', element: <Navigate to="/" /> }
      ]
    },
    {
      path: '*',
      element: <Navigate replace to="/" />
    }
  ])
}
