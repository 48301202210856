import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import mobile from "../assets/images/contact-us-mobile.svg";
import email from "../assets/images/contact-us-email.svg";
import location from "../assets/images/location.svg";
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import facebook from '../assets/images/facebook.svg'
import twitter from '../assets/images/twitter.svg'
import instagram from '../assets/images/instagram.svg'
import linkedin from '../assets/images/linkedin.svg'
import youtube from '../assets/images/youtube.svg'
import { SubscribeNewsletter } from '../api/newsletters';
import * as yup from 'yup';
import Loader from './Loader/Loader';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { allValuesExist } from '../utils/fieldValueCheck';

export const validationSchema = yup.object({
  sFullName: yup.string().required('Name is required'),
  sMembershipNumber: yup.string().required('MembershipNumber is required'),
  sEmail: yup.string().required('Email is required').email('Invalid email format'),
})

function Footer(props) {
  const googleMapsUrl = `https://maps.app.goo.gl/B3Dad5Fovo9HPGjCA`;

  const [alert, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      sFullName: '',
      sMembershipNumber: '',
      sEmail: ''
    },
    validationSchema,
    onSubmit: (values) => {
      subscribeNewsletter(values);
    }
  });

  const { isPending: isSubscribeLoading, mutate: subscribeNewsletter } = useMutation({
    mutationFn: SubscribeNewsletter,
    onSuccess: (response) => {
      setAlert(true);
      setSuccess(true);
      setMessage(response?.data?.message);
      formik.setValues({
        sFullName: '',
        sMembershipNumber: '',
        sEmail: ''
      })
    },
    onError: (error) => {
      setMessage(error?.response?.data?.message);
      setAlert(true);
      setSuccess(false);
    }
  });

  return (<footer className="footer-blue text-white p-4">
    <Container>
      {(isSubscribeLoading) && <Loader />}
      <Row>
        <Col md={6} lg={4} xl={3}>     
          <div className='footer-title'>CONTACT US</div>
          <ul className='contact-us-list'>
            <li>
              <Link to={googleMapsUrl} className='d-flex justify-content-start' target="_blank" rel="noopener noreferrer">
                <img src={location} alt="img"></img>
                <p>5th Floor, The Plutus, opp. Ashka Hospital, Gandhinagar, 382421</p>
              </Link>
            </li>
            <li className='d-flex justify-content-start align-items-center'>
              <a href='tel:+919289819677' className='d-flex justify-content-start align-items-center'>
                <img src={mobile} alt='Contact us mobile' ></img>
                <p>+91 9289819677</p>
              </a>
            </li>
            <li className='d-flex justify-content-start align-items-center'>
              <a href='mailto:gandhinagar@icai.org' className='d-flex justify-content-start align-items-center'>
                <img src={email} alt='Contact us email' ></img>
                <p>gandhinagar@icai.org</p>
              </a>
            </li>
            <div className='social-media-icons'>
              <a href="https://www.facebook.com/profile.php?id=100092990615039&mibextid=ZbWKwL" target="_blank" rel="noreferrer"><img src={facebook} alt='img'></img></a>
              <a href="https://twitter.com/icaigandhinagar?t=gOs3HmgW2YyAz0bxvAhnOg&s=09" target="_blank" rel="noreferrer"><img src={twitter} alt='img'></img></a>
              <a href="https://instagram.com/icaigandhinagar?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer"><img src={instagram} alt='img'></img></a>
              <a href="https://www.linkedin.com/in/gandhinagar-branch-of-wirc-of-icai-064402277" target='_blank' rel="noreferrer"><img src={linkedin} alt='img'></img></a>
              <a href="https://youtube.com/@icaigandhinagar" target="_blank" rel="noreferrer"><img src={youtube} alt='img'></img></a>       
            </div>
          </ul>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <div className='footer-title'>ABOUT US</div>
          <ul className="list-unstyled">
            {/* <li><Link to='/' className="text-white">Home</Link></li> */}
            <li><Link to='/about' className="text-white">About</Link></li>
            {/* <li><Link to='/managing-committee' className="text-white">Managing Committee</Link></li> */}
            <li><Link to='https://www.icai.org' target='_blank' className="text-white">icai.org</Link></li>
            {/* <li><Link to='/links' className="text-white">Useful Links</Link></li> */}
            <li><Link to='/resources' className="text-white">Resources</Link></li>
            <li><Link to='/services' className="text-white">Services</Link></li>
            {/* <li><Link to='/policies/privacy-policy' className="text-white">Privacy Policy</Link></li>
            <li><Link to='/policies/refund-policy' className="text-white">Refund Policy</Link></li>
            <li><Link to='/policies/cancellation-policy' className="text-white">Cancellation Policy</Link></li> */}
            <li><Link to='/policies' className="text-white">Policies</Link></li>
          </ul>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <div className='footer-title'>RESOURCES</div>
          <ul className="list-unstyled">
            <li><Link to='/events' className="text-white">Events</Link></li>
            <li><Link to='/newsletters' className="text-white">Newsletters</Link></li>
            <li><Link to='/gallery' className="text-white">Gallery</Link></li>
          </ul>
        </Col>
        <Col md={6} lg={4} xl={3}>
          <div className='footer-title'>NEWS LETTER</div>
          <Form className='footer-form-class' onSubmit={formik.handleSubmit}>
            <Form.Group controlId="sFullName">
              <Form.Control
                type="text"
                name="sFullName"
                value={formik.values.sFullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group controlId="sMembershipNumber">
              <Form.Control
                type="text"
                maxLength={6}
                name="sMembershipNumber"
                value={formik.values.sMembershipNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Membership No."
              />
            </Form.Group>
            <Form.Group controlId="sEmail">
              <Form.Control
                type="email"
                name="sEmail"
                value={formik.values.sEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Email-id"
              />
            </Form.Group>
            <Button variant="primary" type='submit' className='footer-submit-btn' disabled={!allValuesExist(formik?.values)}>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    <div className="text-center copy-right-text">
      <p className='p-0'>&copy; Gandhinagar ICAI {new Date().getFullYear()}. All rights reserved.</p>
    </div>
    <Modal show={alert && message} onHide={() => setAlert(false)} centered animation={true} bg={success ? 'success' : 'danger'}>
      <Modal.Header closeButton>
        <Modal.Title>{success ? 'Success' : 'Error'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
    </Modal>
  </footer>)
}

Footer.propTypes = {}

export default Footer
